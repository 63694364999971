import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aedd8e7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-picker--wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "date-picker--error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["date-picker--field", [$setup.props.classField, { 'style-placeholder': $props.disabled || !$setup.formatedDate }, { 'size-date-picker': $setup.isReportsRoute }]]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.datePickerClick()))
    }, [
      _createTextVNode(_toDisplayString($setup.valueDatePicker), 1),
      ($props.showClearButton)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require(`@/assets/icons/close.svg`),
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('clearRegistrationDate')), ["stop"]))
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ], 2),
    ($setup.invalidDate)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Invalid Date"))
      : _createCommentVNode("", true),
    ($setup.showDatePicker)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["date-picker--dropdown", $setup.props.position])
        }, [
          _createVNode($setup["Calendar"], {
            modelValue: $setup.props.modelValue,
            "onUpdate:modelValue": $setup.datePicked,
            min: $setup.props.min,
            max: $setup.props.max
          }, null, 8, ["modelValue", "min", "max"])
        ], 2))
      : _createCommentVNode("", true)
  ])), [
    [$setup["vClickOutside"], $setup.closeDatePicker]
  ])
}